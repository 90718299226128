import { Box, useTheme } from '@mui/material';
import './../../../scss/component/_loader.scss';


// Inspired by the former Facebook spinners.
function CircularProgressLoader(props) {
  return (
       <Box className="progress-container" >
         <Box className="progress-outer">
            <Box className="progress-inner" ></Box>
            <Box className="progress-end-dot" ></Box>
         </Box>
    </Box>
  );
}
export default CircularProgressLoader;

