//Built-in Imports
import { lazy } from 'react';
import { createBrowserRouter } from 'react-router-dom';

//Internal Imports
import SidebarLayout from 'src/core/layout/SidebarLayout';
import BaseLayout from 'src/core';
import { GuardedRoute } from './guarded-routes';
import {
  LOGIN,
  SIGNUP,
  ERROR_PAGE,
  ONBOARDING,
  FORGOTPASSWORD,
  SETPASSWORD,
  COMPANIES,
  USERS,
  REGULATION,
  TASKS,
  ACTION_PLAN,
  TASK_PLAN,
  SETTING,
  NEWS,
  DASHBOARD,
  REPORTS,
  AveryAI,
  FINES,
  MORE_INFO_TASK,
  ACTIONPALN_ATTACHMENT,
  REGULATION_POLICIES,
  MY_DOCS,
  FUSION_ONE,
  ADMIN_REGULATION,
  GAP_ACTION_PLAN
} from './../shared/constants/constants';

import * as ROUTES from '../shared/constants/routes';
import { Loader, OnboardingLoader } from './SuspenseLoader';
import ErrorBoundary from 'src/shared/components/errorComponent/ErrorBoundary';

// Pages
// Common pages
const ErrorPage = Loader(lazy(() => ERROR_PAGE));
//FusionOne
const FusionOne = Loader(lazy(() => FUSION_ONE));
// Auth
const Login = Loader(lazy(() => LOGIN));
const ForgotPassword = Loader(lazy(() => FORGOTPASSWORD));
const SignUp = Loader(lazy(() => SIGNUP));
const SetPassword = Loader(lazy(() => SETPASSWORD));

// Onboarding
const Onboarding = OnboardingLoader(lazy(() => ONBOARDING));

// COMPANIES
const Companies = Loader(lazy(() => COMPANIES));

// Users
const Users = Loader(lazy(() => USERS));

// Regulations
const Regulation = Loader(lazy(() => REGULATION));

// Tasks
const Tasks = Loader(lazy(() => TASKS));

const ActionPlan = Loader(lazy(() => ACTION_PLAN));
const ActionPlanAttachment = Loader(lazy(() => ACTIONPALN_ATTACHMENT));
const TaskOperations = Loader(lazy(() => TASK_PLAN));
const RegulationPolicies = Loader(lazy(() => REGULATION_POLICIES));

// News
const News = Loader(lazy(() => NEWS));

// Dashboard
const Dashboard = Loader(lazy(() => DASHBOARD));

//setting
const Setting = Loader(lazy(() => SETTING));

// Avery AI
const Averyai = Loader(lazy(() => AveryAI));

// Avery AI
const Reports = Loader(lazy(() => REPORTS));

// Fines
const Fines = Loader(lazy(() => FINES));

// More info task
const MoreInfoTasks = Loader(lazy(() => MORE_INFO_TASK));

// my docs

const MyDocs = Loader(lazy(() => MY_DOCS));

// Admin Regulation
const AdminRegulation = Loader(lazy(() => ADMIN_REGULATION));

// Gap Action plan
const GapActionPlan = Loader(lazy(() => GAP_ACTION_PLAN));

const router = createBrowserRouter([
  {
    path: '',
    element: <BaseLayout />,
    children: [
      {
        path: ROUTES.LOGIN,
        element: <Login />
      },
      { path: 'forgot-password', element: <ForgotPassword /> },
      { path: 'signup', element: <SignUp /> },
      { path: 'set-password', element: <SetPassword /> }
    ]
  },
  {
    path: ROUTES.BASEPATH,
    element: <SidebarLayout />,
    children: [
      {
        path: ROUTES.COMPANIES,
        element: <GuardedRoute path={ROUTES.COMPANIES} component={Companies} />
      },
      {
        path: ROUTES.FUSIONONE,
        element: <GuardedRoute path={ROUTES.FUSIONONE} component={FusionOne} />
      },
      {
        path: ROUTES.USERS,
        element: <GuardedRoute path={ROUTES.USERS} component={Users} />
      },
      {
        path: ROUTES.TASKS,
        element: <GuardedRoute path={ROUTES.TASKS} component={Tasks} />
      },
      {
        path: ROUTES.REGULATION,
        element: (
          <GuardedRoute path={ROUTES.REGULATION} component={Regulation} />
        ),
      },
      {
        path: ROUTES.SELECTED_REGULATION,
        element: (
          <GuardedRoute
            path={ROUTES.SELECTED_REGULATION}
            component={Regulation}
          />
        )
      },
      {
        path: ROUTES.ACTION_PLAN,
        element: (
          <GuardedRoute
            path={ROUTES.SELECTED_REGULATION}
            component={ActionPlan}
          />
        )
      },
      {
        path: ROUTES.SELECTED_ACTION_PLAN,
        element: (
          <GuardedRoute
            path={ROUTES.SELECTED_ACTION_PLAN}
            component={ActionPlan}
          />
        )
      },
      {
        path: ROUTES.REGULATION_POLICIES,
        element: (
          <GuardedRoute
            path={ROUTES.REGULATION_POLICIES}
            component={RegulationPolicies}
          />
        )
      },
      {
        path: ROUTES.ACTIONPLAN_ATTACHMENT,
        element: (
          <GuardedRoute
            path={ROUTES.ACTIONPLAN_ATTACHMENT}
            component={ActionPlanAttachment}
          />
        )
      },

      {
        path: ROUTES.ADD_TASK,
        element: (
          <GuardedRoute path={ROUTES.ADD_TASK} component={TaskOperations} />
        )
      },
      {
        path: ROUTES.NEWS,
        element: <GuardedRoute path={ROUTES.NEWS} component={News} />
      },
      {
        path: ROUTES.DASHBOARD,
        element: <GuardedRoute path={ROUTES.DASHBOARD} component={Dashboard} />
      },
      {
        path: ROUTES.SETTING,
        element: <GuardedRoute path={ROUTES.SETTING} component={Setting} />
      },
      {
        path: ROUTES.AVERYAI,
        element: <GuardedRoute path={ROUTES.AVERYAI} component={Averyai} />
      },
      {
        path: ROUTES.REPORTS,
        element: <GuardedRoute path={ROUTES.REPORTS} component={Reports} />
      },
      {
        path: ROUTES.FINES,
        element: <GuardedRoute path={ROUTES.FINES} component={Fines} />
      },
      {
        path: ROUTES.TASK_MORE_INFO,
        element: (
          <GuardedRoute
            path={ROUTES.TASK_MORE_INFO}
            component={MoreInfoTasks}
          />
        )
      },
      {
        path: ROUTES.TASK_EDIT_TASK,
        element: (
          <GuardedRoute
            path={ROUTES.TASK_EDIT_TASK}
            component={MoreInfoTasks}
          />
        )
      },
      {
        path: ROUTES.MY_DOCS,
        element: <GuardedRoute component={MyDocs} path={ROUTES.MY_DOCS} />
      },
      {
        path: ROUTES.ADMIN_REGULATION,
        element: (
          <GuardedRoute
            component={AdminRegulation}
            path={ROUTES.ADMIN_REGULATION}
          />
        )
      },
      {
        path: ROUTES.GAP_ACTION_PLAN_PATH,
        element: (
          <ErrorBoundary>
            <GuardedRoute
              component={GapActionPlan}
              path={ROUTES.GAP_ACTION_PLAN_PATH}
            />
          </ErrorBoundary>
        )
      },
      {
        path: ROUTES.GAP_ASSESSMENT,
        element: (
          <div>
            <h1>GAP ASSESSMENT</h1>
          </div>
        )
      }
    ]
  },
  {
    path: ROUTES.ONBOARDING,
    element: <GuardedRoute path={ROUTES.ONBOARDING} component={Onboarding} />
  },
  {
    path: ROUTES.ERROR_PAGE,
    element: <ErrorPage />
  }
]);

export default router;
